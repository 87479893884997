<template>
    <div class="datenschutz">
      <img class="close-datenschutz" src="../assets/tipp/tipp-cross.svg" @click="$router.go(-1)">
      <LanguageSwitch class="lang-switch"></LanguageSwitch>
      <div class="textsheet regularlink">
        <h3>{{$t('datenschutz.titel')}}</h3>
        <p>
          <span v-html="$t('datenschutz.para1')"></span>
        </p>
        <p>
          <span v-html="$t('datenschutz.para2')"></span>
        </p>
      </div>
    </div>
</template>

<script>
import LanguageSwitch from "@/components/LanguageSwitch";
    export default {
        name: "datenschutz",
      components: {
          LanguageSwitch
      }
    }
</script>

<style lang="scss" scoped>
.datenschutz {
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 70px;
}

.lang-switch {
  position: absolute;
  top: 0;
  right: 10%;

  @include tablet-down {
    right: 20%;
  }
}

.close-datenschutz {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 45px;
  cursor: pointer;

  @include mobile-down {
    width: 35px;
  }
}

</style>