<template>
  <div class="leitfaden">
    <img class="close-leitfaden" src="../assets/tipp/tipp-cross.svg" @click="$router.go(-1)">
    <LanguageSwitch class="lang-switch"></LanguageSwitch>
    <div class="textsheet">
      <h3>{{$t('leitfaden.title')}}</h3>
      <p><span v-html="$t('leitfaden.text')"></span></p><br>
      <a :href="$t('leitfaden.downloadurl')" class="button" style="background-color: #FFD381" target="_blank">{{$t('leitfaden.downloadbutton')}}</a>
    </div>
  </div>
</template>

<script>

import LanguageSwitch from "@/components/LanguageSwitch";

export default {
  name: "leitfaden",
  components: {
    LanguageSwitch
  },
}
</script>

<style lang="scss" scoped>
.lang-switch {
  position: absolute;
  top: 0;
  right: 10%;
}

.leitfaden {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 70px;

  @include tablet-down {
    padding: 25px;
  }
}

.close-leitfaden {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 45px;
  cursor: pointer;

  @include tablet-down {
    width: 35px;
    z-index: 5;
  }
}
</style>
